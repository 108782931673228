@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.animation-slideInUp, .animation-slideInLeft, .animation-slideInRight {
  opacity: 0;
}

.animate .animation-slideInUp {
  animation: slideInUp 1s ease-out forwards;
}

.animate .animation-slideInLeft {
  animation: slideInLeft 1s ease-out forwards;
}

.animate .animation-slideInRight {
  animation: slideInRight 1s ease-out forwards;
}

/* Define the slide-in animation */
@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Apply animation to the element when it comes into view */
.slide-in {
  opacity: 0;
  animation: slideIn 0.6s ease-out forwards;
}

/* Optionally, you can add a delay for each item */
.slide-in-delay-1 {
  animation-delay: 0.2s;
}
.slide-in-delay-2 {
  animation-delay: 0.4s;
}
.slide-in-delay-3 {
  animation-delay: 0.6s;
}

input, textarea {
background-color: #04181F !important;
border: 1px solid #04BFBF !important;
}
.icon {
  color:#04BFBF; 
}

.glass {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.glass:hover {
  background: #01465433;
  cursor: pointer;
}

.glassColor {
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.glassColor:hover {
  /* background: #01768e33; */
  background: #01465433;
  cursor: pointer;
}


.gradiendBackground {
  /* background: rgb(63,94,251); */
  /* background: radial-gradient(ci, rgb(195, 195, 196) 0%, rgb(0, 57, 91) 100%); */
  /* background: radial-gradient(ellipse at top, #02455d, transparent),
            radial-gradient(ellipse at top, #034967, transparent); */
  background-image: url("assets/images/bg.png");
  background-size: auto;
}