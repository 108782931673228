.primaryButton {
  /* display: inline-block;
  padding: 0.75rem 1.25rem; */
  /* border-radius: 10rem; */
  color: #061D26;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  /* background: linear-gradient(90deg, #077e7e 0%, #026464 100%); */
  border: none;
  margin: 0.5rem;
  }
 
  .secondaryButton {
    background-color: black;
    color: #04BFBF;
    display: inline-block;
    padding: 0.75rem 1.25rem;
    border-radius: 10rem;
    font-size: 1rem;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;
    border: 1px solid #04BFBF;
    text-transform: uppercase;
    margin: 0.5rem;

  }
  
  .primaryButton:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #05F5F5 0%, #04BFBF 100%);
    /* border-radius: 10rem; */
    z-index: -2;
  }
  
  .primaryButton:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2); /* Adjust this to your liking */
    transition: all 0.3s;
    /* border-radius: 10rem; */
    z-index: -1;
  }
  
  .primaryButton:hover {
    color: #061D26;
  }
  .secondaryButton:hover {
    background: linear-gradient(90deg, #05F5F5 0%, #04BFBF 100%);
    color: black;
  }
  .primaryButton:hover:before {
    width: 100%;
  }
  